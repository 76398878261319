const rows = [
  {
    Edit: "Yes",
    Enabled: "Yes",
    Country: "India",
    AchieverID: 7871,
    Name: "Sagar",
    TitleCaption:
      "Real Madrid boss says leak of audios is Super League payback",
    Courses: 5,
    Notes: 6,
    Talks: 5,
  },
  {
    Edit: "Yes",
    Enabled: "Yes",
    Country: "India",
    AchieverID: 7872,
    Name: "Shashi",
    TitleCaption:
      "Boris Johnson slams racial abuse against England’s Euro 2020 team",
    Courses: 5,
    Notes: 6,
    Talks: 5,
  },
  {
    Edit: "Yes",
    Enabled: "Yes",
    Country: "India",
    AchieverID: 7873,
    Name: "Mahith",
    TitleCaption:
      "Although England took the lead again in the shoot-out, Gianluigi...",
    Courses: 5,
    Notes: 6,
    Talks: 5,
  },
  {
    Edit: "Yes",
    Enabled: "Yes",
    Country: "India",
    AchieverID: 7874,
    Name: "Sagar B",
    TitleCaption: "Euro 2020 | Defensively solid England",
    Courses: 5,
    Notes: 6,
    Talks: 5,
  },
  {
    Edit: "Yes",
    Enabled: "Yes",
    Country: "India",
    AchieverID: 7875,
    Name: "Shashi B",
    TitleCaption:
      "Real Madrid boss says leak of audios is Super League paybackn",
    Courses: 5,
    Notes: 6,
    Talks: 5,
  },
  {
    Edit: "Yes",
    Enabled: "Yes",
    Country: "India",
    AchieverID: 7876,
    Name: "Mahith B",
    TitleCaption:
      "dskahd sjdhksa Euro 2020 | From ‘hated one’ to Euro star, Sterling is England’s inspiration",
    Courses: 5,
    Notes: 6,
    Talks: 5,
  },
  {
    Edit: "Yes",
    Enabled: "Yes",
    Country: "India",
    AchieverID: 7877,
    Name: "Harish",
    TitleCaption: "Bozidar Bandovic is new head coach of Chennaiyin FC",
    Courses: 5,
    Notes: 6,
    Talks: 5,
  },
  {
    Edit: "Yes",
    Enabled: "Yes",
    Country: "India",
    AchieverID: 7878,
    Name: "Deepak",
    TitleCaption:
      "dskahd Copa America final | Rio opens 10% of Maracana Stadium skjdhskajdn",
    Courses: 5,
    Notes: 6,
    Talks: 5,
  },
  {
    Edit: "Yes",
    Enabled: "Yes",
    Country: "India",
    AchieverID: 7879,
    Name: "Sharan Basava",
    TitleCaption:
      "dskahd Ex-Madrid captain Sergio Ramos signs 2-year deal with PSG skjdhskajdn",
    Courses: 5,
    Notes: 6,
    Talks: 5,
  },
  {
    Edit: "Yes",
    Enabled: "Yes",
    Country: "India",
    AchieverID: 7880,
    Name: "Karthik B",
    TitleCaption: "Chennaiyin FC signs custodian Debjit sjdhksa skjdhskajdn",
    Courses: 5,
    Notes: 6,
    Talks: 5,
  },
];

export default rows;
