const rows = [
  {
    Edit: "Yes",
    Enabled: "Yes",
    Country: "India",
    AchieverID: 7871,
    Name: "Sagar",
    UpdatedDate: "12 DEC 21",
    Title: "Real Madrid boss says leak of audios is Super League payback",
    Duration: "10hr",
    Board: "State",
    Courses: "10th",
    Language: "English",
    Subtitle: [
      { value: "Kannada", label: "Kannada" },
      { value: "English", label: "English" },
    ],
    Theme: 200,
    Rating: 4.5,
    Views: 200,
  },
  {
    Edit: "Yes",
    Enabled: "Yes",
    Country: "India",
    AchieverID: 7872,
    Name: "Shashi",
    UpdatedDate: "12 DEC 21",
    Title: "Boris Johnson slams racial abuse against England’s Euro 2020 team",
    Duration: "10hr",
    Board: "State",
    Courses: "10th",
    Language: "English",
    Subtitle: [
      { value: "Kannada", label: "Kannada" },
      { value: "English", label: "English" },
    ],
    Theme: 200,
    Rating: 4.5,
    Views: 200,
  },
  {
    Edit: "Yes",
    Enabled: "Yes",
    Country: "India",
    AchieverID: 7873,
    Name: "Mahith",
    UpdatedDate: "12 DEC 21",
    Title:
      "Although England took the lead again in the shoot-out, Gianluigi...",
    Duration: "10hr",
    Board: "State",
    Courses: "10th",
    Language: "English",
    Subtitle: [
      { value: "Kannada", label: "Kannada" },
      { value: "English", label: "English" },
    ],
    Theme: 200,
    Rating: 4.5,
    Views: 200,
  },
  {
    Edit: "Yes",
    Enabled: "Yes",
    Country: "India",
    AchieverID: 7874,
    Name: "Sagar B",
    UpdatedDate: "12 DEC 21",
    Title: "Euro 2020 | Defensively solid England",
    Duration: "10hr",
    Board: "State",
    Courses: "10th",
    Language: "English",
    Subtitle: [
      { value: "Kannada", label: "Kannada" },
      { value: "English", label: "English" },
    ],
    Theme: 200,
    Rating: 4.5,
    Views: 200,
  },
  {
    Edit: "Yes",
    Enabled: "Yes",
    Country: "India",
    AchieverID: 7875,
    Name: "Shashi B",
    UpdatedDate: "12 DEC 21",
    Title: "Real Madrid boss says leak of audios is Super League paybacking",
    Duration: "10hr",
    Board: "State",
    Courses: "10th",
    Language: "English",
    Subtitle: [
      { value: "Kannada", label: "Kannada" },
      { value: "English", label: "English" },
    ],
    Theme: 200,
    Rating: 4.5,
    Views: 200,
  },
  {
    Edit: "Yes",
    Enabled: "Yes",
    Country: "India",
    AchieverID: 7876,
    Name: "Mahith B",
    UpdatedDate: "12 DEC 21",
    Title:
      "Euro 2020 | From ‘hated one’ to Euro star, Sterling is England’s inspiration",
    Duration: "10hr",
    Board: "State",
    Courses: "10th",
    Language: "English",
    Subtitle: [
      { value: "Kannada", label: "Kannada" },
      { value: "English", label: "English" },
    ],
    Theme: 200,
    Rating: 4.5,
    Views: 200,
  },
  {
    Edit: "Yes",
    Enabled: "Yes",
    Country: "India",
    AchieverID: 7877,
    Name: "Harish",
    UpdatedDate: "12 DEC 21",
    Title: "Bozidar Bandovic is new head coach of Chennaiyin FC",
    Duration: "10hr",
    Board: "State",
    Courses: "10th",
    Language: "English",
    Subtitle: [
      { value: "Kannada", label: "Kannada" },
      { value: "English", label: "English" },
    ],
    Theme: 200,
    Rating: 4.5,
    Views: 200,
  },
  {
    Edit: "Yes",
    Enabled: "Yes",
    Country: "India",
    AchieverID: 7878,
    Name: "Deepak",
    UpdatedDate: "12 DEC 21",
    Title:
      "dskahd Copa America final | Rio opens 10% of Maracana Stadium skjdhskajdn",
    Duration: "10hr",
    Board: "State",
    Courses: "10th",
    Language: "English",
    Subtitle: [
      { value: "Kannada", label: "Kannada" },
      { value: "English", label: "English" },
      { value: "Telugu", label: "Telugu" },
    ],
    Theme: 200,
    Rating: 4.5,
    Views: 200,
  },
  {
    Edit: "Yes",
    Enabled: "Yes",
    Country: "India",
    AchieverID: 7879,
    Name: "Sharan Basava",
    UpdatedDate: "12 DEC 21",
    Title:
      "dskahd Ex-Madrid captain Sergio Ramos signs 2-year deal with PSG skjdhskajdn",
    Duration: "10hr",
    Board: "State",
    Courses: "10th",
    Language: "English",
    Subtitle: [
      { value: "Kannada", label: "Kannada" },
      { value: "English", label: "English" },
    ],
    Theme: 200,
    Rating: 4.5,
    Views: 200,
  },
  {
    Edit: "Yes",
    Enabled: "Yes",
    Country: "India",
    AchieverID: 7880,
    Name: "Karthik B",
    UpdatedDate: "12 DEC 21",
    Title: "Chennaiyin FC signs custodian Debjit sjdhksa skjdhskajdn",
    Duration: "10hr",
    Board: "State",
    Courses: "10th",
    Language: "English",
    Subtitle: [
      { value: "Kannada", label: "Kannada" },
      { value: "English", label: "English" },
    ],
    Theme: 200,
    Rating: 4.5,
    Views: 200,
  },
];

export default rows;
