import React, { useState } from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import rows from "../../../achieversTalksData.js";
import EditIcon from "../../../assets/images/EditIcon.svg";
import ModalComponent from "../../Modal/Modal";
import "./achieversTalks.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Box from "@mui/material/Box";
// import { Redirect } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
import Select, { components }  from "react-select";

const initialValues = {
  country: "",
  board: "",
  course: "",
  group: "",
  audioLanguage: "",
  theme: "",
  title: "",
  subtitle: [],
  videoLink: "",
  achieversId: "",
  name: "",
};
const names = [
  { value: "Kannada", label: "Kannada" },
  { value: "English", label: "English" },
  { value: "Tamil", label: "Tamil" },
  { value: "Hindi", label: "Hindi" },
  { value: "Telugu", label: "Telugu" },
];

// const names = ["Kannada", "English", "Telugu", "Hindi"];

// const validationSchema = Yup.object({
//   email: Yup.string().required("Email is required"),
//   password: Yup.string().required("Password is required"),
// });
const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          className="talksCheckbox"
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

function AchieversTalks() {
  const [show, setShow] = useState(false);
  const [pic, setPic] = useState("");

  const handleClose = () => {
    setShow(false);
    setEditValue(false);
    setPersonName([]);
    setPic("");
  };
  const handleShow = () => setShow(true);

  function onSubmit(value, submit) {
    console.log(value);
    // submit.setSubmitting(false);
    // submit.resetForm();
  }

  const thead_column = [
    "Edit",
    "Enable",
    "Country",
    "Achiever ID",
    "Name",
    "Updated Date",
    "Title",
    "Duration",
    "Board",
    "Courses",
    "Audio Language",
    "Subtitle",
    "Theme",
    "Rating",
    "Views",
  ];

  const countries = ["Country", "India", "Nepal", "Shrilanka"];
  const boards = ["All Board", "State", "CBSE", "ICSE"];
  const courses = [
    "All Courses",
    "2nd",
    "3rd",
    "4th",
    "5th",
    "6th",
    "7th",
    "8th",
    "9th",
    "10th",
  ];
  const groups = ["All Groups", "Kannada", "Science", "Mathematics"];
  const languages = ["Kannada", "English", "Telugu"];
  const themes = ["None", "100", "200", "300"];

  const [editValue, setEditValue] = useState(false);
  const [rowValues, setRowValues] = useState({
    country: "",
    name: "",
    achieversId: 0,
    title: "",
    board: "",
    course: "",
    subtitle: [],
    language: "",
    theme: "",
  });
  function handelEdit(
    countryValue,
    nameValue,
    achieverIDValue,
    titleValue,
    boardValue,
    coursesValue,
    subtitleValue,
    languageValue,
    themeValue
  ) {
    setEditValue(true);
    setRowValues((prevState) => ({
      ...prevState,
      country: countryValue,
      name: nameValue,
      achieversId: achieverIDValue,
      title: titleValue,
      board: boardValue,
      course: coursesValue,
      subtitle: subtitleValue,
      language: languageValue,
      theme: themeValue,
    }));
    setShow(true);
  }
  var subtitle1 = rowValues.subtitle;
  console.log(rowValues.subtitle);

  const [selectOption, setSelectOption] = useState([]);
  function handelSelect(e) {
    setSelectOption(Array.isArray(e) ? e.map((x) => x.label) : []);
  }
  // console.log(selectOption);

  const [personName, setPersonName] = useState([]);
  const [personN, setPersonN] = useState([]);

  // function handelDropDown(event) {
  //   const newValue = event.target.value;
  //   setPersonN(newValue);
  //   console.log(personN);
  // }

  // const handleChangeDropDown = (event) => {
  //   // const {
  //   //   target: { value },
  //   // } = event;
  //   const value = event.target.value;
  //   setPersonName(
  //     // On autofill we get a stringified value.
  //     typeof value === "string" ? value.split(",") : value
  //   );
  // };
  // console.log(personName);

  // function handelEditProp() {
  //   setPersonName(rowValues.subtitle);
  // }

  return (
    <div className="container-fluid">
      <div className="head2">
        <div>
          <h1 className="h1AchieversNotes">Achievers Talks</h1>
        </div>
        <div className="headButton">
          <button className="statusButton">All Status</button>

          <button className="addAchieverTalksButton" onClick={handleShow}>
            Add Talks
          </button>
        </div>
      </div>

      <div>
        <div className="tableContainer">
          <table className="tableAchTalks">
            <thead>
              <tr>
                {thead_column.map((el, key) => {
                  return <th key={key}>{el}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {rows.map((rows, key) => {
                return (
                  <tr key={key}>
                    <td>
                      <button
                        className="editButton"
                        onClick={() =>
                          handelEdit(
                            rows.Country,
                            rows.Name,
                            rows.AchieverID,
                            rows.Title,
                            rows.Board,
                            rows.Courses,
                            rows.Subtitle,
                            rows.Language,
                            rows.Theme
                          )
                        }
                      >
                        <img src={EditIcon} alt="" />
                      </button>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="checkbox"
                        id=""
                        name=""
                        value=""
                      />
                    </td>
                    <td>{rows.Country}</td>
                    <td className="achieversID">{rows.AchieverID}</td>
                    <td className="rowNames">{rows.Name}</td>
                    <td className="date">{rows.UpdatedDate}</td>
                    <td className="titleCaption">{rows.Title}</td>
                    <td>{rows.Duration}</td>
                    <td>{rows.Board}</td>
                    <td>{rows.Courses}</td>
                    <td>{rows.Language}</td>
                    {/* <td>{rows.Subtitle.label.join(", ")}</td> */}
                    <td>{rows.Subtitle.map((e, key) => {
                      return e.value
                    }).join(", ")}</td>
                     {/* <td>{rows.Subtitle[0].value}</td> */}
                    <td>{rows.Theme}</td>
                    <td>{rows.Rating}</td>
                    <td className="views">{rows.Views}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="paginationTalks">
          <Stack spacing={2} align="center">
            <Pagination
              count={5}
              color="primary"
              align="center"
              showFirstButton
              showLastButton
            />
          </Stack>
        </div>
      </div>

      <ModalComponent
        show={show}
        onClick={handleClose}
        title={editValue ? "Edit Achievers Talks" : "Add Achievers Talks"}
        className="addAchieverPopup"
      >
        <div className="addTalksForm">
          <Formik
            initialValues={editValue ? rowValues : initialValues}
            // validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ handleChange, setFieldValue }) => {
              return (
                <div>
                  <Form>
                    <label className="lableAchiever">Country</label>
                    <br />
                    <Field
                      as="select"
                      name="country"
                      onChange={handleChange}
                      className="selectOption"
                      // value={editValue ? rowValues.country : undefined}
                    >
                      {countries.map((e, key) => {
                        return <option key={key}>{e}</option>;
                      })}
                    </Field>
                    <br />
                    <label className="lableAchiever">Board</label>
                    <br />
                    <Field
                      as="select"
                      name="board"
                      onChange={handleChange}
                      className="selectOption"
                      // value={editValue ? rowValues.board : undefined}
                    >
                      {boards.map((e, key) => {
                        return <option key={key}>{e}</option>;
                      })}
                    </Field>
                    <br />
                    <label className="lableAchiever">Course</label>
                    <br />
                    <Field
                      as="select"
                      name="course"
                      onChange={handleChange}
                      className="selectOption"
                      // value={editValue ? rowValues.courses : undefined}
                    >
                      {courses.map((e, key) => {
                        return <option key={key}>{e}</option>;
                      })}
                    </Field>
                    <br />
                    <label className="lableAchiever">Group K12</label>
                    <br />
                    <Field
                      as="select"
                      name="group"
                      onChange={handleChange}
                      className="selectOption"
                    >
                      {groups.map((e, key) => {
                        return <option key={key}>{e}</option>;
                      })}
                    </Field>
                    <br />
                    <label className="lableAchiever">Audio Language</label>
                    <br />
                    <Field
                      as="select"
                      name="audioLanguage"
                      onChange={handleChange}
                      className="selectOption"
                      // value={editValue ? rowValues.language : undefined}
                    >
                      {languages.map((e, key) => {
                        return <option key={key}>{e}</option>;
                      })}
                    </Field>
                    <br />
                    <label className="lableAchiever">Theme</label>
                    <br />
                    <Field
                      as="select"
                      name="theme"
                      onChange={handleChange}
                      className="selectOption"
                      // value={editValue ? rowValues.theme : undefined}
                    >
                      {themes.map((e, key) => {
                        return <option key={key}>{e}</option>;
                      })}
                    </Field>

                    <label className="lableAchiever">Title</label>
                    <br />
                    <Field
                      type="text"
                      name="title"
                      maxLength="50"
                      className="inputTitle"
                      // value={editValue ? rowValues.title : undefined}
                    />
                    <label className="maxChr">Max 50 character</label>
                    <br />
                    {/* <ErrorMessage name="name" component="div">
                             {(error) => <div className={styles.errors}>{error}</div>}
                            </ErrorMessage> */}

                    <label className="lableAchiever">Subtitle</label>
                    <br />
                    {/* {editValue ? ( */}
                      <Select
                        isMulti
                        defaultValue={rowValues.subtitle}
                        options={names}
                        onChange={handelSelect}
                        className="selectOption1"
                        components={{
                          Option
                        }}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                      ></Select>
                    {/* ) : ( */}
                      {/* <Select
                        isMulti
                        options={names}
                        onChange={handelSelect}
                        className="selectOption1"
                      ></Select>
                    )} */}
                    {/* <Select isMulti options={editValue?rowValues.subtitle:names} onChange={handelSelect}
                     className="selectOption1"></Select> */}
                    {/* {editValue ? handelEditProp : undefined} */}
                    {/* {editValue ? (
                      <FormControl>
                        <Select
                          multiple
                          // defaultValue={rowValues.subtitle}
                          // value={rowValues.subtitle}
                          value={personName}
                          onChange={handleChangeDropDown}
                          renderValue={(selected) => selected.join(", ")}
                          className="selectOption1"
                        >
                          {names.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox
                                checked={personName.indexOf(name) > -1}
                                className="checkboxDropDown"
                              />
                              <ListItemText
                                primary={name}
                                className="dropdownText"
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : ( */}
                      {/* <FormControl>
                        <Select
                          multiple
                          value={personName}
                          // onChange={(e) => {handleChangeDropDown(e);
                          //   handelDropDown(e);}}
                          onChange={handleChangeDropDown}
                          renderValue={(selected) => selected.join(", ")}
                          className="selectOption1"
                        >
                          {names.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox
                                checked={personName.indexOf(name) > -1}
                                className="checkboxDropDown"
                              />
                              <ListItemText
                                primary={name}
                                className="dropdownText"
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl> */}
                    {/* ) */}
                    {/* } */}
                    {/* <FormControl>
                      <Select
                        multiple
                        value={personName}
                        // onChange={(e) => {handleChangeDropDown(e);
                        //   handelDropDown(e);}}
                          onChange={handleChangeDropDown}
                        renderValue={(selected) => selected.join(", ")}
                        className="selectOption1"
                      >
                        {names.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox checked={personName.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl> */}
                    {/* <Select
                      options={colorOptions}
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      // onChange={handleChange}
                      allowSelectAll={true}
                      className="selectOption"
                      defaultValue={editValue ? rowValues.subtitle : ""}
                    /> */}
                    {/* <FormControl>
                      <Select
                        multiple
                        value={editValue ? rowValues.subtitle : personName}
                        onChange={handleChangeSelect}
                        // input={<OutlinedInput label="Name" />}
                        // MenuProps={MenuProps}
                        className="inputTitle"
                      >
                        {names.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, personName, theme)}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl> */}
                    <br />
                    <label className="lableAchiever">Video Link</label>
                    <br />
                    <Field type="text" name="videoLink" className="input" />
                    <br />

                    <label className="lableAchiever">Achievers Id</label>
                    <br />
                    <Field
                      type="text"
                      name="achieversId"
                      className="input"
                      value={editValue ? rowValues.achieversId : undefined}
                    />

                    <br />

                    <label className="lableAchiever">Name</label>
                    <br />
                    <Field
                      type="text"
                      name="name"
                      className="input"
                      value={editValue ? rowValues.name : undefined}
                    />
                    <label htmlFor="attachFIle" className="talkThumbnail">
                      Attach thumbnail
                    </label>
                    <div className="talkThumbnailHiddenText">
                      <img
                        src={pic ? URL.createObjectURL(pic) : null}
                        alt={pic ? pic.name : null}
                        className={pic ? "talkPreviewImg" : ""}
                      />
                      <input
                        id="file"
                        name="file"
                        type="file"
                        onChange={(event) => {
                          setFieldValue("file", event.currentTarget.files[0]);
                          setPic(event.currentTarget.files[0]);
                        }}
                        className="talkThumbnailbutton"
                      />
                      {pic ? (
                        <button
                          name="removeFile"
                          type="button"
                          onClick={() => setPic("")}
                          className="talkThumbnailremoveButton"
                        >
                          Remove{" "}
                        </button>
                      ) : null}
                    </div>

                    <br />
                    <hr />

                    <Box component="div" className="footerAddAchiever">
                      <button onClick={handleClose} className="btnCancel">
                        <div>Cancel</div>
                      </button>

                      <button
                        type="submit"
                        onClick={onSubmit}
                        className="btnSave"
                      >
                        <div>Save</div>
                      </button>
                    </Box>
                  </Form>
                </div>
              );
            }}
          </Formik>
        </div>
      </ModalComponent>
    </div>
  );
}

export default AchieversTalks;
