const rows = [
  {
    Edit: "Yes",
    Enabled: "Yes",
    Country: "India",
    AchieverID: 7871,
    Name: "Sagar",
    NotesHexa: 123456,
    UpdatedDate: "12 DEC 21",
    Title: "Real Madrid boss says leak of audios is Super League payback",
    Board: "State",
    Courses: "4th",
    Subject: "English",
    Language: "English",
    Topic: "Eye",
    Rating: 4.5,
    Views: 200,
    ViewTime: 10,
  },
  {
    Edit: "Yes",
    Enabled: "Yes",
    Country: "India",
    AchieverID: 7872,
    Name: "Shashi",
    NotesHexa: 123457,
    UpdatedDate: "12 DEC 21",
    Title: "Boris Johnson slams racial abuse against England’s Euro 2020 team",
    Board: "State",
    Courses: "10th",
    Subject: "Kannada",
    Language: "Kannada",
    Topic: "Kannda",
    Rating: 4.5,
    Views: 200,
    ViewTime: 10,
  },
  {
    Edit: "Yes",
    Enabled: "Yes",
    Country: "India",
    AchieverID: 7873,
    Name: "Mahith",
    NotesHexa: 123458,
    UpdatedDate: "12 DEC 21",
    Title:
      "Although England took the lead again in the shoot-out, Gianluigi...",
    Board: "State",
    Courses: "10th",
    Subject: "Science",
    Language: "English",
    Topic: "F = ma",
    Rating: 4.5,
    Views: 200,
    ViewTime: 10,
  },
  {
    Edit: "Yes",
    Enabled: "Yes",
    Country: "India",
    AchieverID: 7874,
    Name: "Sagar B",
    NotesHexa: 123459,
    UpdatedDate: "12 DEC 21",
    Title: "Euro 2020 | Defensively solid England",
    Board: "State",
    Courses: "10th",
    Subject: "Science",
    Language: "English",
    Topic: "F = ma",
    Rating: 4.5,
    Views: 200,
    ViewTime: 10,
  },
  {
    Edit: "Yes",
    Enabled: "Yes",
    Country: "India",
    AchieverID: 7875,
    Name: "Shashi B",
    NotesHexa: 123460,
    UpdatedDate: "12 DEC 21",
    Title: "Real Madrid boss says leak of audios is Super League paybacking",
    Board: "State",
    Courses: "6th",
    Subject: "Science",
    Language: "English",
    Topic: "Gravity",
    Rating: 4.5,
    Views: 200,
    ViewTime: 10,
  },
  {
    Edit: "Yes",
    Enabled: "Yes",
    Country: "India",
    AchieverID: 7876,
    Name: "Mahith B",
    NotesHexa: 123461,
    UpdatedDate: "12 DEC 21",
    Title:
      "Euro 2020 | From ‘hated one’ to Euro star, Sterling is England’s inspiration",
    Board: "State",
    Courses: "8th",
    Subject: "Science",
    Language: "English",
    Topic: "Gravity",
    Rating: 4.5,
    Views: 200,
    ViewTime: 10,
  },
  {
    Edit: "Yes",
    Enabled: "Yes",
    Country: "India",
    AchieverID: 7877,
    Name: "Harish",
    NotesHexa: 123462,
    UpdatedDate: "12 DEC 21",
    Title: "Bozidar Bandovic is new head coach of Chennaiyin FC",
    Board: "State",
    Courses: "8th",
    Subject: "Science",
    Language: "English",
    Topic: "Gravity",
    Rating: 4.5,
    Views: 200,
    ViewTime: 10,
  },
  {
    Edit: "Yes",
    Enabled: "Yes",
    Country: "India",
    AchieverID: 7878,
    Name: "Deepak",
    NotesHexa: 123463,
    UpdatedDate: "12 DEC 21",
    Title:
      "dskahd Copa America final | Rio opens 10% of Maracana Stadium skjdhskajdn",
    Board: "State",
    Courses: "8th",
    Subject: "Science",
    Language: "English",
    Topic: "Gravity",
    Rating: 4.5,
    Views: 200,
    ViewTime: 10,
  },
  {
    Edit: "Yes",
    Enabled: "Yes",
    Country: "India",
    AchieverID: 7879,
    Name: "Sharan Basava",
    NotesHexa: 123464,
    UpdatedDate: "12 DEC 21",
    Title:
      "dskahd Ex-Madrid captain Sergio Ramos signs 2-year deal with PSG skjdhskajdn",
    Board: "State",
    Courses: "8th",
    Subject: "Science",
    Language: "English",
    Topic: "Gravity",
    Rating: 4.5,
    Views: 200,
    ViewTime: 10,
  },
  {
    Edit: "Yes",
    Enabled: "Yes",
    Country: "India",
    AchieverID: 7880,
    Name: "Karthik B",
    NotesHexa: 123465,
    UpdatedDate: "12 DEC 21",
    Title: "Chennaiyin FC signs custodian Debjit sjdhksa skjdhskajdn",
    Board: "State",
    Courses: "8th",
    Subject: "Science",
    Language: "English",
    Topic: "Gravity",
    Rating: 4.5,
    Views: 200,
    ViewTime: 10,
  },
];

export default rows;
