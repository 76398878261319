import React, { useState } from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import rows from "../../../achieversNotesData.js";
import EditIcon from "../../../assets/images/EditIcon.svg";
import ModalComponent from "../../Modal/Modal";
import "./achieversNotes.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Box from "@mui/material/Box";
// import { Redirect } from "react-router-dom";

const initialValues = {
  country: "",
  board: "",
  course: "",
  subject: "",
  language: "",
  topic: "",
  notesTitle: "",
  achieversId: "",
  name: "",
  attachFile: null,
};
// const validationSchema = Yup.object({
//   email: Yup.string().required("Email is required"),
//   password: Yup.string().required("Password is required"),
// });

function AchieversNotes() {
  const [show, setShow] = useState(false);
  const [fileName, setFileName] = useState("");
  const [pic, setPic] = useState("");
  // const [preview, setPreview] = useState();

  const handleClose = () => {
    setShow(false);
    setEditValue(false);
    setPic("");
  };
  const handleShow = () => setShow(true);

  function onSubmit(value, submit) {
    console.log(value);
    // submit.setSubmitting(false);
    // submit.resetForm();
  }

  function uploadFile(e) {
    // let formdata = new FormData();
    let file = e.target.files[0];

    // formdata.append("filetoupload", file)
    setFileName(file.name);
  }

  const thead_column = [
    "Edit",
    "Enable",
    "Country",
    "Achiever ID",
    "Name",
    "Notes Hexa",
    "Updated Date",
    "Title",
    "Board",
    "Courses",
    "Subject",
    "Language",
    "Topic",
    "Rating",
    "Views",
    "View Time (Hr)",
  ];

  const countries = ["Country", "India", "Nepal", "Shrilanka"];
  const boards = ["All Board", "State", "CBSE", "ICSE"];
  const courses = [
    "All Courses",
    "1st",
    "2nd",
    "3rd",
    "4th",
    "5th",
    "6th",
    "7th",
    "8th",
    "9th",
    "10th",
  ];
  const subjects = ["All Subjects", "Kannada", "Science", "Mathematics"];
  const languages = ["Kannada", "English", "Telugu"];
  const topics = ["All Topics", "Eye", "Gravity", "Sun"];

  const [editValue, setEditValue] = useState(false);
  const [rowValues, setRowValues] = useState({
    country: "",
    name: "",
    achieversId: 0,
    title: "",
    board: "",
    course: "",
    subject: "",
    language: "",
    topic: "",
  });
  function handelEdit(
    countryValue,
    nameValue,
    achieverIDValue,
    titleValue,
    boardValue,
    coursesValue,
    subjectValue,
    languageValue,
    topicValue
  ) {
    setEditValue(true);
    setRowValues((prevState) => ({
      ...prevState,
      country: countryValue,
      name: nameValue,
      achieversId: achieverIDValue,
      title: titleValue,
      board: boardValue,
      course: coursesValue,
      subject: subjectValue,
      language: languageValue,
      topic: topicValue,
    }));
    setShow(true);
  }

  console.log(rowValues);

  return (
    <div className="container-fluid">
      <div className="head2">
        <div>
          <h1 className="h1AchieversNotes">Achievers notes</h1>
        </div>
        <div className="headButton">
          <button className="statusButton">All Status</button>

          <button className="addAchieverNotesButton" onClick={handleShow}>
            Add Achiever note
          </button>
        </div>
      </div>

      <div>
        <div className="tableContainer">
          <table className="tableAchNotes">
            <thead>
              <tr>
                {thead_column.map((el, key) => {
                  return <th key={key}>{el}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {rows.map((rows, key) => {
                return (
                  <tr key={key}>
                    <td>
                      <button
                        className="editButton"
                        onClick={() =>
                          handelEdit(
                            rows.Country,
                            rows.Name,
                            rows.AchieverID,
                            rows.Title,
                            rows.Board,
                            rows.Courses,
                            rows.Subject,
                            rows.Language,
                            rows.Topic
                          )
                        }
                      >
                        <img src={EditIcon} alt="" />
                      </button>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="checkbox"
                        id=""
                        name=""
                        value=""
                      />
                    </td>
                    <td>{rows.Country}</td>
                    <td className="achieversID">{rows.AchieverID}</td>
                    <td className="rowNames">{rows.Name}</td>
                    <td>{rows.NotesHexa}</td>
                    <td className="date">{rows.UpdatedDate}</td>
                    <td className="titleCaption">{rows.Title}</td>
                    <td>{rows.Board}</td>
                    <td>{rows.Courses}</td>
                    <td>{rows.Subject}</td>
                    <td>{rows.Language}</td>
                    <td>{rows.Topic}</td>
                    <td>{rows.Rating}</td>
                    <td className="views">{rows.Views}</td>
                    <td className="viewTime">{rows.ViewTime}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="paginationNotes">
          <Stack spacing={2} align="center">
            <Pagination
              count={5}
              color="primary"
              align="center"
              showFirstButton
              showLastButton
            />
          </Stack>
        </div>
      </div>

      <ModalComponent
        show={show}
        onClick={handleClose}
        title={editValue ? "Edit Achievers Notes" : "Add Achievers Notes"}
        className="addAchieverPopup"
      >
        <div className="addAchieverForm">
          <Formik
            initialValues={editValue ? rowValues : initialValues}
            // validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ handleChange, setFieldValue }) => {
              return (
                <div>
                  <Form>
                    <label className="lableAchiever">Country</label>
                    <br />
                    <Field
                      as="select"
                      name="country"
                      onChange={handleChange}
                      className="selectOption"
                      // defaultValue={editValue ? rowValues.country : undefined}
                    >
                      {countries.map((e, key) => {
                        return <option key={key}>{e}</option>;
                      })}
                    </Field>
                    <br />
                    <label className="lableAchiever">Board</label>
                    <br />
                    <Field
                      as="select"
                      name="board"
                      onChange={handleChange}
                      className="selectOption"
                      // value={editValue ? rowValues.board : undefined}
                    >
                      {boards.map((e, key) => {
                        return <option key={key}>{e}</option>;
                      })}
                    </Field>
                    <br />
                    <label className="lableAchiever">Course</label>
                    <br />
                    <Field
                      as="select"
                      name="course"
                      onChange={handleChange}
                      className="selectOption"
                      // value={editValue ? rowValues.courses : undefined}
                    >
                      {courses.map((e, key) => {
                        return <option key={key}>{e}</option>;
                      })}
                    </Field>
                    <br />
                    <label className="lableAchiever">Subject</label>
                    <br />
                    <Field
                      as="select"
                      name="subject"
                      onChange={handleChange}
                      className="selectOption"
                      // value={editValue ? rowValues.subject : undefined}
                    >
                      {subjects.map((e, key) => {
                        return <option key={key}>{e}</option>;
                      })}
                    </Field>
                    <br />
                    <label className="lableAchiever">Language</label>
                    <br />
                    <Field
                      as="select"
                      name="language"
                      onChange={handleChange}
                      className="selectOption"
                      // value={editValue ? rowValues.language : undefined}
                    >
                      {languages.map((e, key) => {
                        return <option key={key}>{e}</option>;
                      })}
                    </Field>
                    <br />
                    <label className="lableAchiever">Topic</label>
                    <br />
                    <Field
                      as="select"
                      name="topic"
                      onChange={handleChange}
                      className="selectOption"
                      // value={editValue ? rowValues.topic : undefined}
                    >
                      {topics.map((e, key) => {
                        return <option key={key}>{e}</option>;
                      })}
                    </Field>

                    <label className="lableAchiever">Notes Title</label>
                    <br />
                    <Field
                      type="text"
                      name="title"
                      className="input"
                      // value={editValue ? rowValues.title : ""}
                    />
                    <br />
                    {/* <ErrorMessage name="name" component="div">
                             {(error) => <div className={styles.errors}>{error}</div>}
                            </ErrorMessage> */}
                    <label className="lableAchiever">Achievers ID</label>
                    <br />
                    <Field
                      type="text"
                      name="achieversId"
                      className="input"
                      // value={editValue ? rowValues.achieversId : ""}
                    />
                    <br />

                    <label className="lableAchiever">Name</label>
                    <br />
                    <Field
                      type="text"
                      name="name"
                      className="input"
                      // value={editValue ? rowValues.name : ""}
                    />

                    <br />

                    <label className="lableAchiever">Attach file</label>
                    <br />
                    <label htmlFor="attachFIle" className="inputAttach">
                      Attach notes file
                    </label>
                    <Field
                      type="file"
                      name="attachFIle"
                      className="insideText"
                      accept=".pdf"
                      onChange={uploadFile}
                    />
                    {fileName != "" ? (
                      <p className="fileName">{fileName}</p>
                    ) : (
                      ""
                    )}
                    <label htmlFor="attachFIle" className="thumbnail">
                      Attach thumbnail
                    </label>
                    <div className="thumbnailHiddenText">
                      <img
                        src={pic ? URL.createObjectURL(pic) : null}
                        alt={pic ? pic.name : null}
                        className={pic ? "previewImg" : ""}
                      />
                      <input
                        id="file"
                        name="file"
                        type="file"
                        onChange={(event) => {
                          setFieldValue("file", event.currentTarget.files[0]);
                          setPic(event.currentTarget.files[0]);
                        }}
                        className="thumbnailbutton"
                      />
                      {pic ? (
                        <button
                          name="removeFile"
                          type="button"
                          onClick={() => setPic("")}
                          className="thumbnailremoveButton"
                        >
                          Remove{" "}
                        </button>
                      ) : null}
                    </div>

                    <hr />

                    <Box component="div" className="footerAddAchiever">
                      <button onClick={handleClose} className="btnCancel">
                        <div>Cancel</div>
                      </button>

                      <button
                        type="submit"
                        onClick={onSubmit}
                        className="btnSave"
                      >
                        <div>Save</div>
                      </button>
                    </Box>
                  </Form>
                </div>
              );
            }}
          </Formik>
        </div>
      </ModalComponent>
    </div>
  );
}

export default AchieversNotes;
