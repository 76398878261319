import React, { useState } from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import rows from "../../../achieversInfoData.js";
import EditIcon from "../../../assets/images/EditIcon.svg";
import ModalComponent from "../../Modal/Modal";
import "./achieversInfo.css";
import Box from "@mui/material/Box";
import { Formik, Form, Field, ErrorMessage } from "formik";

const initialValues = {
  name: "",
  titleCaption: "",
  aboutLink: "",
  country: "",
};

function AchieversInfo() {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    return (
      <div>
        {setShow(false)}
        {setEditValue(false)}
      </div>
    );
  };
  const handleShow = () => setShow(true);

  function onSubmit(value) {
    console.log(value);
  }

  const thead_column = [
    "Edit",
    "Enable",
    "Country",
    "Achiever ID",
    "Name",
    "Title Caption",
    "Courses",
    "Notes",
    "Talks",
  ];

  const countries = ["Country", "India", "Nepal", "Shrilanka"];

  const [editValue, setEditValue] = useState(false);
  const [rowValues, setRowValues] = useState({
    name: "",
    tile: "",
    country: "",
  });

  function handleEdit(nameValue, titleValue, countryValue) {
    setEditValue(true);
    setRowValues((prevState) => ({
      ...prevState,
      name: nameValue,
      title: titleValue,
      country: countryValue,
    }));
    setShow(true);
  }

  return (
    <div className="container-fluid">
      <div className="head2">
        <div>
          <h1 className="h1AchieversInfo">Achievers info</h1>
        </div>
        <div className="headButton">
          <button className="statusButton">All Status</button>

          <button className="addAchieverButton" onClick={handleShow}>
            Add Achiever
          </button>
        </div>
      </div>

      <div>
        <div>
          <table className="tableAch">
            <thead>
              <tr>
                {thead_column.map((el, key) => {
                  return <th key={key}>{el}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {rows.map((rows, key) => {
                return (
                  <tr key={key}>
                    <td>
                      <button
                        className="editButton"
                        onClick={() =>
                          handleEdit(rows.Name, rows.TitleCaption, rows.Country)
                        }
                      >
                        <img src={EditIcon} alt="" />
                      </button>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="checkbox"
                        id=""
                        name=""
                        value=""
                      />
                    </td>
                    <td>{rows.Country}</td>
                    <td>{rows.AchieverID}</td>
                    <td className="rowName">{rows.Name}</td>
                    <td className="titleCaption">{rows.TitleCaption}</td>
                    <td>{rows.Courses}</td>
                    <td className="rowText">{rows.Notes}</td>
                    <td className="rowText">{rows.Talks}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="paginationInfo">
          <Stack spacing={2} align="center">
            <Pagination
              count={5}
              color="primary"
              align="center"
              showFirstButton
              showLastButton
            />
          </Stack>
        </div>
      </div>

      <ModalComponent
        show={show}
        onClick={handleClose}
        title={editValue ? "Edit Achievers" : "Add Achievers"}
        className="addAchieverPopup"
      >
        <div className="addAchieverForm">
          <Formik
            initialValues={editValue ? rowValues : initialValues}
            // validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ handleChange }) => {
              return (
                <div>
                  <Form>
                    <label className="lableAchiever">Name</label>
                    <br />
                    <Field
                      type="text"
                      name="name"
                      className="input"
                      // value={editValue ? rowValues.name : undefined}
                    ></Field>
                    <br />
                    {/* <ErrorMessage name="name" component="div">
                             {(error) => <div className={styles.errors}>{error}</div>}
                            </ErrorMessage> */}
                    <label className="lableAchiever">Title caption</label>
                    <br />
                    <Field
                      type="text"
                      name="title"
                      className="input"
                      // value={editValue === true ? rowValues.title : undefined}
                    ></Field>
                    <br />

                    <label className="lableAchiever">About link</label>
                    <br />
                    <Field type="text" name="aboutlink" className="input" />

                    <br />
                    <label className="lableAchiever">Country</label>
                    <br />
                    <Field
                      as="select"
                      name="country"
                      onChange={handleChange}
                      className="selectOption"
                      // value={editValue === true ? rowValues.country : undefined}
                    >
                      {countries.map((e, key) => {
                        return <option key={key}>{e}</option>;
                      })}
                    </Field>

                    <br />
                    <hr />

                    <Box component="div" className="footerAddAchiever">
                      <button onClick={handleClose} className="btnCancel">
                        <div>Cancel</div>
                      </button>

                      <button type="submit" className="btnSave">
                        <div>Save</div>
                      </button>
                    </Box>
                  </Form>
                </div>
              );
            }}
          </Formik>
        </div>
      </ModalComponent>
    </div>
  );
}

export default AchieversInfo;
