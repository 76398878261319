import React, { useState } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import AchieversInfo from "../Screens/AchieversInfo/AchieversInfo.jsx";
import AchieversNotes from "../Screens/AchieversNotes/AchieversNotes";
import AchieversTalks from "../Screens/AchieversTalks/AchieversTalks";
// import GroupCourse from "../Screens/GroupCourse/GroupCourse";
import Reports from "../Screens/Reports/Reports";
// import routes from "../../routes";
import Navbar from "../Header/Navbar";

const switchRoutes = (
  <Switch>
    <Route exact path="/admin/achievers-info" component={AchieversInfo} />
    <Route exact path="/admin/achievers-notes" component={AchieversNotes} />
    <Route exact path="/admin/achievers-talks" component={AchieversTalks} />
    {/* <Route exact path="/admin/group-course" component={GroupCourse} /> */}
    <Route exact path="/admin/reports" component={Reports} />
    <Redirect from="/admin" to="/admin/achievers-info" />
  </Switch>
);

const Layout = () => {
  const getRoute = () => {
    return window.location.pathname !== null;
  };

  if (window.localStorage.getItem("token") === null) {
    return (window.location.href = "/");
  }
  return (
    <>
      <div>
        <Navbar />
        <div>{getRoute() ? <div>{switchRoutes}</div> : null}</div>
        <p className="developer">Developed by Sagar Bazar</p>
      </div>
    </>
  );
};

export default Layout;
