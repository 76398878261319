import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { Redirect, withRouter, Link } from "react-router-dom";
import LogoWhite from "../../assets/images/Logo-white.png";
import "./navbar.css";

var countryValue = "";
var boardValue;
var courseValue;
var subjectValue;

function ButtonAppBar(props) {
  const [state, setstate] = useState({
    country: "",
    board: "",
    course: "",
    subject: "",
  });
  const { history } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  function handleChange(e, selectedOption) {
    if (selectedOption === "india") {
      countryValue = e.target.value;
    } else if (selectedOption === "board") {
      boardValue = e.target.value;
    } else if (selectedOption === "course") {
      courseValue = e.target.value;
    } else if (selectedOption === "subject") {
      subjectValue = e.target.value;
    }
    setstate({
      ...state,
      country: countryValue,
      board: boardValue,
      course: courseValue,
      subject: subjectValue,
    });
  }
  console.log(state.country);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const countries = ["Country", "India", "Nepal", "Shrilanka"];
  const boards = ["All Board", "State", "CBSE", "ICSE"];
  const courses = ["All Courses", "2nd", "3rd", "4th", "5th"];
  const subjects = ["All Subjects", "Kannada", "Science", "Mathematics"];
  const topics = ["All Topics", "Eye", "Gravit", "Sun"];

  return (
    <div className="navbar">
      <div className="navbar1">
        <div>
          <IconButton
            size="large"
            edge="start"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleMenu}
            className="menuIcon"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <Link to="/admin/achievers-info" className="linkOtherPage">
              <MenuItem onClick={handleClose} className="iconPlacementInfo" >Achievers Info</MenuItem>
            </Link>
            <Link to="/admin/achievers-notes" className="linkOtherPage">
              <MenuItem onClick={handleClose} className="iconPlacementNotes" >Achiever Notes</MenuItem>
            </Link>
            <Link to="/admin/achievers-talks" className="linkOtherPage">
              <MenuItem onClick={handleClose} className="iconPlacementTalks" >Achiever Talks</MenuItem>
            </Link>
            <Link to="/admin/group-course" className="linkOtherPage">
              <MenuItem onClick={handleClose} className="iconPlacementGroup" >Group Course</MenuItem>
            </Link>
            <Link to="/admin/reports" className="linkOtherPage">
              <MenuItem onClick={handleClose} className="iconPlacementReports" >Reports</MenuItem>
            </Link>
          </Menu>
        </div>

        <img src={LogoWhite} alt="" />
        <div className="searchInput">
          <input className="navsearch" type="search" placeholder="Search" />
        </div>

        <select
          className="navbut"
          name="India"
          id="India"
          onChange={(e) => handleChange(e, "india")}
        >
          {countries.map((e, key) => {
            return <option key={key}>{e}</option>;
          })}
        </select>
        <select
          className="navbut"
          name="Board"
          id="Board"
          onChange={(e) => handleChange(e, "board")}
        >
          {boards.map((e, key) => {
            return <option key={key}>{e}</option>;
          })}
        </select>
        <select
          className="navbut"
          name="Course"
          id="Course"
          onChange={(e) => handleChange(e, "course")}
        >
          {courses.map((e, key) => {
            return <option key={key}>{e}</option>;
          })}
        </select>
        <select
          className="navbut"
          name="Subject"
          id="Subject"
          onChange={(e) => handleChange(e, "subject")}
        >
          {subjects.map((e, key) => {
            return <option key={key}>{e}</option>;
          })}
        </select>
        <select
          className="navbut"
          name="Topics"
          id="Topics"
          onChange={(e) => handleChange(e, "topic")}
        >
          {topics.map((e, key) => {
            return <option key={key}>{e}</option>;
          })}
        </select>
      </div>
      <div>
        <button className="navbut1">Sagar</button>
      </div>
    </div>
  );
}

export default withRouter(ButtonAppBar);
